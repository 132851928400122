import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IStageHistoryMap, StageHistoryState, stageHistoryFeatureKey } from './stage-history.reducers'
import { DEPENDANT_STAGES } from '@events'

const stageHistorySelector = createFeatureSelector<StageHistoryState>(stageHistoryFeatureKey)

export const selectDependentStageHistoryMap = (dependentId: string) => createSelector(
    stageHistorySelector,
    (state: StageHistoryState) => state.stageHistoryMap.get(dependentId) ?? []
)

const mergeStageHistory = (eStage: IStageHistoryMap, nStage: IStageHistoryMap) => {
    let stageHistory = {...eStage}
    if (nStage.start_date < eStage.start_date) {
        stageHistory.start_date = nStage.start_date
        stageHistory.attachment = nStage.attachment || eStage.attachment
    }
    if (!nStage.end_date) {
        stageHistory.end_date = null
        stageHistory.observation_attachment = nStage.observation_attachment || eStage.observation_attachment
        stageHistory.portfolio_attachment = nStage.portfolio_attachment || eStage.portfolio_attachment
    } else if (eStage.end_date && (nStage.end_date > eStage.end_date!)) {
        stageHistory.end_date = nStage.end_date
        stageHistory.observation_attachment = nStage.observation_attachment || eStage.observation_attachment
        stageHistory.portfolio_attachment = nStage.portfolio_attachment || eStage.portfolio_attachment
    }
    setStageAttachment(stageHistory, nStage)
    return stageHistory
}

const setStageAttachment =(eStage: IStageHistoryMap, nStage: IStageHistoryMap) => {
    if (!eStage.attachment) {
        eStage.attachment = nStage.attachment
    }
    if (!eStage.portfolio_attachment) {
        eStage.portfolio_attachment = nStage.portfolio_attachment
    }
    if (!eStage.observation_attachment) {
        eStage.observation_attachment = nStage.observation_attachment
    }
}

export const selectCombinedDependentStageHistoryMap = (dependentId: string[]) => createSelector(
    stageHistorySelector,
    (state: StageHistoryState) => {
        let history:IStageHistoryMap[] = []
        dependentId.forEach(id => {
            const h = state.stageHistoryMap.get(id)
            if (h) {
                history = history.concat(h)
            }
        })
        const stageMap = new Map<string, IStageHistoryMap>()
        history.forEach((cur) => {
            const key = cur.stage
            const saved = stageMap.get(key)
            stageMap.set(key, saved ? mergeStageHistory(saved, cur) : cur)
        })
        return DEPENDANT_STAGES
            .map((s) => stageMap.get(s))
            .filter((s) => s) as IStageHistoryMap[]
    }
)
