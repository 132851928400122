import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable, Subject, of } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { CurriculumPlans } from '../models/curriculum-plans'
import { ObservationEntry } from '../models/activity-feed'
import { Cacheable } from '@bh/data'
import { IStageHistoryMap } from '@events'

@Injectable({
    providedIn: 'root'
})
export class MyLearnersService {
    private cancelLoadCurriculumPlans$ = new Subject()
    private cancelLoadObservations$ = new Subject()

    constructor(private http: HttpClient) {}

    public getCurriculumPlans(
        dependentId: string,
        startDate: string | Date,
        endDate: string | Date
    ): Observable<CurriculumPlans[]> {
        const start = moment(startDate).format('YYYY-MM-DD')
        const end = moment(endDate).format('YYYY-MM-DD')

        return this.http
            .get<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/curriculum_plans?dependents=${dependentId}&start=${start}&end=${end}`
            )
            .pipe(
                takeUntil(this.cancelLoadCurriculumPlans$),
                map((data) =>
                    data && data.length > 0 ? data.map((plan) => new CurriculumPlans(plan)) : []
                )
            )
    }

    public getDevObservations(
        dependantIds: string[],
        stages: string[],
        stageHistory: IStageHistoryMap[],
        limit: number,
        lastFetchedDate: string = '',
    ): Observable<MyLearnersObservations> {
        return !lastFetchedDate
            ? this._getCurrentDevObservations(dependantIds, stages, stageHistory, limit)
            : this._getPastDevObservations(dependantIds, stages, lastFetchedDate, stageHistory, limit)
    }

    private getEndDate(lastFetchedDate: string, stageEndDate: string | null) {
        if (
            lastFetchedDate &&
            stageEndDate &&
            moment(lastFetchedDate).isBefore(stageEndDate)
        ) {
            return lastFetchedDate
        }
        return stageEndDate
    }

    private transformObservationReportRequestBody(stageHistory: IStageHistoryMap[], lastFetchedDate: string) {
        return stageHistory.map(h => ({
            dependent_id: h.dependent_id,
            start_date: h.start_date,
            end_date: this.getEndDate(
                lastFetchedDate,
                h.end_date)
        }))
    }

    @Cacheable({ key: 'currentDevObservations', numKeyArgs: 2  })
    private _getCurrentDevObservations(
        _dependantIds: string[],
        _stages: string[],
        stageHistory: IStageHistoryMap[],
        limit: number,
    ): Observable<MyLearnersObservations> {
        return this._getDevObservations(
            '',
            stageHistory,
            limit
        )
    }

    @Cacheable({ key: 'pastDevObservations', numKeyArgs: 3 , ttl: 1.44e7 })
    private _getPastDevObservations(
        _dependantIds: string[],
        _stages: string[],
        lastFetchedDate: string,
        stageHistory: IStageHistoryMap[],
        limit: number,
    ): Observable<MyLearnersObservations> {
        return this._getDevObservations(
            lastFetchedDate,
            stageHistory,
            limit
        )
    }

    private _getDevObservations(
        lastFetchedDate: string,
        stageHistory: IStageHistoryMap[],
        limit: number,
    ): Observable<MyLearnersObservations> {
        const params: any = {
            limit,
        }
        const body = this.transformObservationReportRequestBody(stageHistory, lastFetchedDate)
        return this.http
            .post<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/observations_report`,
                body,
                {
                    params
                }
            )
            .pipe(
                takeUntil(this.cancelLoadObservations$),
                map((data: any) => {
                    const lastFetchedDate = moment.unix(data.last_date).format('YYYY-MM-DD')
                    return {
                        observations:
                            data && data.entries.length > 0
                                ? data.entries.map((obs: any) => new ObservationEntry(obs))
                                : [],
                        lastFetchedDate
                    } as MyLearnersObservations
                })
            )
    }

    cancelObservationRequest() {
        this.cancelLoadObservations$.next()
        return of(null)
    }

    cancelCurriculumPlanRequest() {
        this.cancelLoadCurriculumPlans$.next()
        return of(null)
    }
}

export interface MyLearnersObservations {
    observations: ObservationEntry[]
    lastFetchedDate: string
    isPaginationDisabled: boolean
}
