import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { MsNotification, Notification } from '../models/notification'

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient) {}

    private _getNotifications(guardianId: string): Observable<unknown[]> {
        return this.http.get<unknown[]>(
            `${environment.config().security.backendHost}/guardian/${guardianId}/notifications`
        )
    }

    private _getNotificationsHome(guardianId: string): Observable<MsNotification> {
        return this.http.get<MsNotification>(
            `${
                environment.config().security.gatewayHost
            }/api/notifications/v1/guardian/${guardianId}/home`
        )
    }

    public getNotifications(guardianId: string): Observable<Notification[]> {
        return this._getNotifications(guardianId).pipe(
            map((data: unknown[]) =>
                data && data.length > 0
                    ? data
                          .map((d) => new Notification(d))
                          .sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())
                    : []
            )
        )
    }

    public getNotificationsHome(guardianId: string): Observable<Notification[]> {
        return this._getNotificationsHome(guardianId).pipe(
            map((data: MsNotification) => {
                // really, it's just this line that re-maps the new endpoint data to the old model
                let formattedData = data.emergency_alerts.concat(data.reminders)
                formattedData = formattedData.map((d: any) => {
                    d.show_in_homepage = true
                    d.show_in_reminders = true
                    d.read_in_reminders = false
                    return d
                })

                // then return to original handling
                formattedData =
                    formattedData?.length > 0
                        ? formattedData
                              .map((d) => new Notification(d))
                              .sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())
                        : []
                return formattedData
            })
        )
    }

    public updateNotificationReadStatus(
        guardianId: string,
        notificationId: string,
        readInMessages: boolean,
        readInReminders: boolean,
        isRead = true
    ): Observable<void> {
        return this.http
            .patch<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/notification/${notificationId}`,
                {
                    read: isRead,
                    read_in_messages: readInMessages,
                    read_in_reminders: readInReminders
                }
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }

    public markNotificationAsDeleted(
        guardianId: string,
        notificationId: string,
        isRead: boolean,
        showInMessages: boolean,
        showInReminders: boolean,
        showInHomepage: boolean,
        readInMessages: boolean,
        readInReminders: boolean
    ): Observable<void> {
        return this.http
            .patch<unknown[]>(
                `${
                    environment.config().security.backendHost
                }/guardian/${guardianId}/notification/${notificationId}`,
                {
                    read: isRead,
                    show_in_messages: showInMessages,
                    show_in_reminders: showInReminders,
                    show_in_homepage: showInHomepage,
                    read_in_messages: readInMessages,
                    read_in_reminders: readInReminders
                }
            )
            .pipe(
                map(() => {
                    return
                })
            )
    }
}
