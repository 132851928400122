import { createFeatureSelector, createSelector } from '@ngrx/store'
import moment from 'moment'
import { Domain } from '../models/activity-feed-domain'
import {
    Dependant,
    DependantByCenterMap,
    DependantMap,
    DependentScheduleMap,
    ScheduledDependent
} from '../models/dependant'
import {
    dependantFeatureKey,
    DependantState,
    getDependentName,
    getUniqueActiveDependents,
} from './dependant.reducers'
import { selectCenterInfo } from '../center-info/center-info.selectors'
import { CenterInfo, CenterInfoMap } from '../models/center-info'
import { DependentStageHistory } from '../models/dependent-stage-history'
import { DateUtil } from '../utils/date-util'

const dependantSelector = createFeatureSelector<DependantState>(dependantFeatureKey)

export const selectActiveDependants = createSelector(dependantSelector, (state: DependantState) => {
    const deps = getUniqueActiveDependents(
        state.dependantList.filter((d) => d.status === 'active').slice(),
        state.activityFeedDate
    )
    return deps
})

/**
 * Used in Home to config topnav
 */
export const selectEarliestMemory = createSelector(dependantSelector, (state: DependantState) => {
    return state.dependantList[0]?.earliestMemory
})

/**
 * Used in memory
 */
export const selectNamedDependantsUniqueByStatus = createSelector(
    dependantSelector,
    (state: DependantState) => {
        return [...sortByAgeAndName(state.dependantList.slice())]
    }
)

/**
 *  Used in home
 */
export const selectDependantsByDateHome = createSelector(
    [dependantSelector, selectCenterInfo],
    (state: DependantState, centers: CenterInfoMap) => {
        return getDependentsForDate(state, centers, false)
    }
)

/**
 *  Used in quick action pages
 */
export const selectDependantsByDate = createSelector(
    [dependantSelector, selectCenterInfo],
    (state: DependantState, centers: CenterInfoMap) => {
        return getDependentsForDate(state, centers)
    }
)

const getDependentsForDate = (state: DependantState, centers: CenterInfoMap, isQuickAction=true) => {
    const activityDate = moment(state.activityFeedDate).format('yyyy-MM-DD')
    const isTodaysFeed = moment().isSame(moment(state.activityFeedDate), 'day')
    const dependentsScheduledForFeedDate = state.dependantListDate[activityDate]
    const dependants: Dependant[] = []

    dependentsScheduledForFeedDate?.forEach((d) => {
        let dependant = getDependentById(state.dependantList, d.id)

        if (dependant) {
            if (
                isTodaysFeed &&
                (isQuickAction || !dependant.isScheduledToday) &&
                CenterInfo.isPastCenterAutoCheckout(centers[dependant.centerId])
            ) {
                dependant = getNextDayDependant(state, dependant)
            }
            dependants.push(dependant)

        }
    })

    return sortByAgeAndName(dependants)
}

/**
 * filter the active dependents and dependents who is scheduled to next working day
 * used in arrival-pickup, arrival note, marks absense componet
 */
export const selectActiveScheduleDependents = createSelector(
    selectDependantsByDate,
    (dependents: Dependant[]): Dependant[] => {
        return activeScheduleDependents(dependents)
    }
)

/**
 * Used in arrival-pickup, mark-absence
 */
export const selectActiveScheduleDependentsLength = createSelector(
    selectActiveScheduleDependents,
    (dependents: Dependant[]): number => {
        return dependents.length
    }
)

/**
 * Used in arrival-pickup
 */
export const selectActiveScheduleCentersMap = createSelector(
    selectActiveScheduleDependents,
    (dependents: Dependant[]): DependantByCenterMap => {
        return mapDepsToCenters(dependents)
    }
)

/**
 * Used in arrival-pickup, arrival-notes
 */
export const selectDependantDateMap = createSelector(
    selectActiveScheduleDependents,
    (dependents: Dependant[]): DependantMap => {
        return listToMap(dependents)
    }
)

const getDependentById = (dependantList: Dependant[], id: string): Dependant | undefined => {
    return dependantList.find((d) => d.id === id)
}

export const getNextDayDependant = (state: DependantState, dep: Dependant): Dependant => {
    const depInfo = state.dependantListNextDate.find((d) => d.firstName === dep.firstName)
    if (depInfo) {
        const dependant = getDependentById(state.dependantList, depInfo.id)
        if (dependant) {
            return {
                ...dependant,
                isAfterCenterCheckout: true
            }
        }
    }

    return { ...dep }
}

export const listToMap = (dependantList: Dependant[]): DependantMap => {
    const dependents = dependantList.reduce((acc, d) => ({ ...acc, [d.id]: d }), {} as DependantMap)
    dependantList.forEach((d) => {
        Dependant.getDependentIds(d.scheduledCenterIds).forEach((id) => {
            if (id !== d.id) {
                dependents[id] = {
                    ...d,
                    id: id
                }
            }
        })
    })
    return dependents
}

const mapDepsToCenters = (dependents: Dependant[]) => {
    return dependents.reduce((acc, d) => {
        let centerMap: ScheduledDependent[] = []
        const today = moment().format('dd')

        const currentDep = { centerId: d.centerId, dependentId: d.id }

        centerMap = [
            ...new Set(
                Object.values(d.scheduledCenterIds)
                    .map((s) => {
                        return s
                    })
                    .filter((s) => {
                        return s.centerId !== currentDep.centerId && s.centerId !== ''
                    })
            )
        ]

        centerMap.forEach((center) => {
            acc[center.centerId]
                ? acc[center.centerId].concat({
                      ...d,
                      id: center.dependentId,
                      centerId: center.centerId,
                      isScheduledToday:
                          center.dependentId === d.scheduledCenterIds[today].dependentId
                  })
                : (acc[center.centerId] = [
                      {
                          ...d,
                          id: center.dependentId,
                          centerId: center.centerId,
                          isScheduledToday:
                              center.dependentId === d.scheduledCenterIds[today].dependentId
                      }
                  ])
        })

        return { ...acc, [d.centerId]: (acc[d.centerId] || []).concat(d) }
    }, {} as DependantByCenterMap)
}

export const selectDependantList = createSelector(
    dependantSelector,
    (state: DependantState) => state.dependantList
)

export const selectActivePipelineDependantList = createSelector(
    selectDependantList,
    (dependents: Dependant[]) =>
        dependents.filter((dep) => getActiveDependents(dep))
)

export const selectActiveDependantListWithMulticenters = createSelector(
    selectDependantList,
    (dependents: Dependant[]) => dependents.filter((dep) => getActiveDependents(dep))
)

export const selectDependantById = (dependantId: string) =>
    createSelector(dependantSelector, (state: DependantState) => {
        return (
            getDependentById(state.dependantList, dependantId) ||
            ({} as Dependant)
        )
    })

export const selectDependantMap = createSelector(dependantSelector, (state: DependantState) => {
    return listToMap(state.dependantList)
})

/**
 * Used in home
 */
export const selectCurrentCenterForDependent = createSelector(
    dependantSelector,
    (state: DependantState) => {
        return state.centerIdCheckIn
    }
)

/**
 * used
 */
export const selectCenters = createSelector(
    dependantSelector,
    (state: DependantState): string[] => {
        return state.centers
    }
)

/**
 * Used in home, messages
 */
export const selectCentersMap = createSelector(
    dependantSelector,
    (state: DependantState): DependantByCenterMap => {
        return mapDepsToCenters(state.dependantList)
    }
)

/**
 * Used in home
 */
export const selectActiveDependantUniqueCenterIds = createSelector(
    selectDependantsByDate,
    (dependents: Dependant[]): string[] => {
        return Array.from(new Set(dependents.map((d) => d.centerId)))
    }
)

/**
 * Used in arrival-notes, arrival-pickup
 */
export const selectDependantsByCenterMap = createSelector(
    selectActiveScheduleDependents,
    (dependents: Dependant[]): DependantByCenterMap => {
        return dependents.reduce(
            (acc, d) => ({ ...acc, [d.centerId]: (acc[d.centerId] || []).concat(d) }),
            {} as DependantByCenterMap
        )
    }
)

/**
 * Used in shell-guard and alerts/reminders
 */
export const selectDependantListLength = createSelector(
    dependantSelector,
    (state: DependantState) => state.dependantList.length
)

/**
 * Used in mark-absence
 */
export const selectActiveDependentsLength = createSelector(
    selectActiveDependants,
    (dependents: Dependant[]): number => {
        return dependents.length
    }
)

export const selectActivityFeedDate = createSelector(
    dependantSelector,
    (state: DependantState) => state.activityFeedDate
)

export const selectActivityFeed = createSelector(
    dependantSelector,
    (state: DependantState) => state.activityFeed
)

export const selectActivityFeedDomain = createSelector(
    dependantSelector,
    (state: DependantState) => state.activityFeedDomain
)

/**
 * Used in my-learners
 */
export const selectWithdrawnChildCount = createSelector(
    dependantSelector,
    (state: DependantState) => state.withdrawnChildrenCount
)

/**
 * Used in Home
 */
export const selectActiveDependentHome = createSelector(
    dependantSelector,
    (state: DependantState) => {
        return state.selectedDependant
    }
)

/**
 * Used in My-Learners
 * All stage history to be merged.
 * Transitions and default stage selected are based on active dependent profile
 * as visible on Home Page
 */
export const selectMyLearnerDependants = createSelector(
    selectDependantList,
    selectDependantsByDateHome,
    (allDependants: Dependant[], todaysDependants: Dependant[]) => {
        const response: Dependant[] = []
        todaysDependants.forEach((td) => {
            const stageHistory: DependentStageHistory[] = []
            td.multipleIds
                .map((id) => getDependentById(allDependants, id.dependentId))
                .forEach((d) => d && stageHistory.unshift(...d.stageHistory))
            response.push({
                ...td,
                stageHistory,
            })
        })
        return response
    }
)

export const selectActiveDependantsScheduledDays = createSelector(
    selectActiveScheduleDependents,
    selectDependantList,
    (uniqueDependents: Dependant[], allDependents: Dependant[]) => {
        return getDependentScheduledDays(uniqueDependents, allDependents)
    }
)

const getDependentScheduledDays = (uniqueDep: Dependant[], allDep: Dependant[]) => {
    return uniqueDep.map((item) => {
        const selectedDependents = allDep
            .filter((i) => getDependentName(i) === getDependentName(item) && getActiveDependents(i))
            .reduce((acc: any, dp) => {
                const scheduledCenterIds = getGroupedSchedule(dp.scheduledCenterIds)

                acc[dp.firstName] = acc[dp.firstName]
                    ? {
                          ...acc[dp.firstName],
                          ...scheduledCenterIds
                      }
                    : scheduledCenterIds

                return acc
            }, {})

        return {
            ...item,
            scheduledCenterIds: {
                ...item.scheduledCenterIds,
                ...selectedDependents[item.firstName]
            }
        }
    })
}

const getActiveDependents = (dep: Dependant) => {
    const active = dep.status === 'active' || dep.status === 'scheduled'
    const suspended = dep.status === 'suspended'

    return active || suspended
}

export const getGroupedSchedule = (scheduledCenterIds: DependentScheduleMap) => {
    let scheduledIds = {}
    for (const key in scheduledCenterIds) {
        if (scheduledCenterIds[key].centerId) {
            scheduledIds = {
                ...scheduledIds,
                [key]: scheduledCenterIds[key]
            }
        }
    }

    return scheduledIds
}

export const selectGroupedActivityForDependentOnDate = (dependentId: string, date: string): any =>
    createSelector(dependantSelector, (state: DependantState) => {
        if (
            state.activityFeed &&
            state.activityFeed[date] &&
            state.activityFeed[date][dependentId]
        ) {
            const activityFeed = state.activityFeed[date][dependentId]

            return {
                ...activityFeed.group,
                observations: activityFeed.group.observations.map((o) => ({
                    ...o,
                    selections: o.selections.map((s) => ({
                        ...s,
                        domain: findActivityFeedDomain(
                            state.activityFeedDomain.domainList,
                            s.domainId
                        ),
                        attribute: findActivityFeedDomain(
                            state.activityFeedDomain.attributeList,
                            s.attributeId
                        ),
                        progression: findActivityFeedDomain(
                            state.activityFeedDomain.progressionList,
                            s.progressionId
                        )
                    }))
                }))
            }
        }

        return {}
    })

const findActivityFeedDomain: (domainList: Domain[], id: string) => string | undefined = (
    domainList,
    id
) => {
    return domainList.find((d) => id === d.id)?.name
}

const sortByAgeAndName: (deps: Array<Dependant>) => Array<Dependant> = (deps: Array<Dependant>) => {
    return deps.sort((a: Dependant, b: Dependant) => {
        if (b.birthDate && a.birthDate) {
            if (b.birthDate.valueOf() === a.birthDate.valueOf()) {
                return a.firstName.localeCompare(b.firstName)
            }
            return b.birthDate.valueOf() - a.birthDate.valueOf()
        }
        return a.firstName.localeCompare(b.firstName)
    })
}

export const activeScheduleDependents: (deps: Array<Dependant>) => Array<Dependant> = (
    deps: Array<Dependant>
) => {
    const nextDate = DateUtil.getNextWeekDate(moment().toDate())
    return deps.filter(
        (d) =>
            (d.status === 'active' || d.status === 'scheduled') &&
            moment(d.enrollmentDate).isSameOrBefore(nextDate)
    )
}

/**
 * Used in my-learners and memories
 */
export const selectUniqueDependantList = createSelector(
    dependantSelector,
    (state: DependantState) => {
        const dependantList = state.dependantList
        const uniqueDependents = getUniqueActiveDependents(
            dependantList.slice(),
            state.activityFeedDate
        )

        return uniqueDependents
    }
)
