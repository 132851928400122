import { createAction, props } from '@ngrx/store'
import { ActivityFeed, ActivityFeedMap } from '../models/activity-feed'
import { ActivityFeedDomain } from '../models/activity-feed-domain'
import { Dependant, DependantInfo } from '../models/dependant'
import { MemoriesSummary } from '../models/memories-summary'
import { MemoriesRequest } from '../memories/memories.actions'
import { MemoryDocument, MemoryMedia } from '../models/memory-data'
import { CenterAttendance } from '../models/center-attedance'

export const loadDependant = createAction(
    '[Dependant] Load Dependant',
    props<{ guardianId: string }>()
)

export const loadDependantSuccess = createAction(
    '[Dependant] Load Dependant Success',
    props<{ dependantList: Dependant[] }>()
)

export const loadDependantError = createAction(
    '[Dependant] Load Dependant Error',
    props<{ error: Error }>()
)

export const loadDependantByDate = createAction(
    '[Dependant] Load Dependant By Date ',
    props<{ guardianId: string; date: Date }>()
)

export const loadDependantByDateSuccess = createAction(
    '[Dependant] Load Dependant By Date Success',
    props<{
        dependantListDate: DependantInfo[]
        dependantListNextDate: DependantInfo[]
        date: string
        centers: string[]
    }>()
)

/*export const loadDependantByDateError = createAction(
    '[Dependant] Load Dependant Error',
    props<{ error: Error }>()
)*/

export const loadActivityFeedDomain = createAction('[Dependant] Load Activity Feed Domain')

export const loadActivityFeedDomainSuccess = createAction(
    '[Dependant] Load Activity Feed Domain Success',
    props<{ activityFeedDomain: ActivityFeedDomain }>()
)

export const loadActivityFeedDomainError = createAction(
    '[Dependant] Load Activity Feed Domain Error',
    props<{ error: Error }>()
)

export const loadActivityFeed = createAction(
    '[Dependant] Load Activity Feed for Dependant',
    props<{ dependantId: string; date: Date }>()
)

export const loadActivityFeedSuccess = createAction(
    '[Dependant] Load Activity Feed for Dependant Success',
    props<{ date: string; activityFeed: ActivityFeed | null, dependantAttendance: CenterAttendance[] }>()
)

export const loadActivityFeedError = createAction(
    '[Dependant] Load Activity Feed for Dependant Error',
    props<{ error: Error }>()
)

export const loadActivityFeedByPeriod = createAction(
    '[Dependant] Load Activity Feed for Dependant by Period',
    props<{ dependantId: string; start: Date; end: Date; memoryId?: string }>()
)

export const loadActivityFeedByPeriodSuccess = createAction(
    '[Dependant] Load Activity Feed for Dependant by Period Success',
    props<{ dependantId: string; activityFeed: ActivityFeed[]; memoryId?: string }>()
)

export const loadActivityFeedByPeriodError = createAction(
    '[Dependant] Load Activity Feed for Dependant by Period Error',
    props<{ error: Error }>()
)

export const cancelActivityFeedRequest = createAction(
    '[Dependant] Cancel load Activity Feed for Dependant'
)

export const loadBrightJourneyReportsByPeriod = createAction(
    '[Dependant] Load Bright Journey Reports for Dependant by Period',
    props<{ dependantIds: string[]; stages: string[]; skip?: number; limit?: number }>()
)

export const loadBrightJourneyReportsByPeriodSuccess = createAction(
    '[Dependant] Load Bright Journey Reports for Dependant by Period Success',
    props<{ dependantIds: string[]; reports: any }>()
)

export const loadBrightJourneyReportsByPeriodError = createAction(
    '[Dependant] Load Bright Journey Reports for Dependant by Period Error',
    props<{ error: Error }>()
)

export const cancelLoadBrightJourneyReportsRequest = createAction(
    '[Dependant] Cancel load Bright Journey Reports for Dependant by Period'
)

export const prefetchMemories = createAction(
    '[Dependant] Prefetching memories',
    props<{ dependentId: string }>()
)

export const setPaginationCompleted = createAction(
    '[Memories] set pagination completed',
    props<{ completed: boolean }>()
)

export const loadMemoriesSummaryForDependantList = createAction(
    '[Memories] Load Memories Summary for Dependant List',
    props<{ dependantIdList: string[] }>()
)

export const loadMemoriesSummaryForDependantListSuccess = createAction(
    '[Memories] Load Memories Summary for Dependant List with Success',
    props<{ memoriesSummaryList: MemoriesSummary[][] }>()
)

export const loadMemoriesSummaryForDependantListError = createAction(
    '[Memories] Load Memories Summary for Dependant List with Error',
    props<{ error: Error }>()
)

export const selectedDependant = createAction(
    '[Dependant] Selected Dependant from the list',
    props<{ dependent: Dependant }>()
)

export const loadMultiMemoriesByPeriod = createAction(
    '[Dependant] Load Multi Memories for Dependants by Period',
    props<{ memoryMediaRequest: MemoriesRequest; loadMedia: boolean; loadReports: boolean }>()
)

export const loadMultiMemoriesByPeriodSuccess = createAction(
    '[Dependant] Load Multi Memories for Dependants by Period Success',
    props<{
        memoriesDataResponse: MemoriesDataResponse
        dependantIdsList: Array<string>
        loadMedia: boolean
        loadReports: boolean
        activityFeeds: ActivityFeedMap
    }>()
)

export const loadMultiMemoriesByPeriodError = createAction(
    '[Dependant] Load Multi Memories for Dependants by Period Error',
    props<{ error: Error }>()
)

export const loadLatestMultiMemories = createAction(
    '[Dependant] Load Latest Multi Memories',
    props<{ dependentIdList: string[] }>()
)

export const loadMemoriesPorfolioByPeriodSuccess = createAction(
    '[Dependant] Load Bright Journey Reports for DependantIds by Period Success',
    props<{ memoriesDocuments: MemoryDocument[] }>()
)
export interface ActivityFeedRequest {
    dependentId: string
    startDate: Date
    endDate: Date
}

export interface MemoriesDataResponse {
    memoriesMedia: MemoryMedia[]
    memoriesDocuments: MemoryDocument[]
}
