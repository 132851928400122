import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Observable } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { selectDependantMap } from '../dependant/dependant.selectors'
import { CalendarEntry } from '../models/calendar-entry'
import { DependantMap } from '../models/dependant'

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private SHORT_DATE_FORMAT = 'yyyy-MM-DD'
    private dependentList: DependantMap = {}

    constructor(private http: HttpClient, private store: Store) { }

    public getAllCalendarEntries(guardianId: string, startDate: Date): Observable<CalendarEntry[]> {
        const start = moment(startDate)
            .subtract(1, 'months')
            .startOf('month')
            .format(this.SHORT_DATE_FORMAT)
        const endDate = moment(startDate)
            .add(1, 'month')
            .endOf('month')
            .format(this.SHORT_DATE_FORMAT)
        return this.http
            .get(
                `${environment.config().security.backendHost
                }/guardian/${guardianId}/calendar?start_date=${start}&end_date=${endDate}`
            )
            .pipe(
                filter((data) => !!data),
                map((data) => data as any[]),
                switchMap((data: any[]) => this.processCalendarData(data))
            )
    }

    processCalendarData(data: any[]): Observable<CalendarEntry[]> {
        return this.store.select(selectDependantMap).pipe(
            take(1),
            map((depList) => {
                this.dependentList = depList
                const calendarEvents = data.filter((e) => e.entry_type !== 'please_bring')
                return this.createCalendarEntries(calendarEvents)
            })
        )
    }

    createCalendarEntries(calendarEvents: any[]): CalendarEntry[] {
        const entries: CalendarEntry[] = []

        calendarEvents.forEach((entry) => {
            const calEntry = new CalendarEntry(entry)
            const firstNameList = this.getFirstNameList(entry.dependent_ids)
            const active = this.areDependantsActive(entry.dependent_ids)

            if (this.shouldAddEntry(entry, calEntry, entries, active)) {
                entries.push(this.createEntry(entry, calEntry, firstNameList))
            }
        })

        return entries
    }

    getFirstNameList(dependentIds: string[]): string[] {
        return dependentIds.map((id) => this.dependentList[id]?.firstName).filter(Boolean)
    }

    areDependantsActive(dependentIds: string[]): boolean {
        return dependentIds.every((id) => this.dependentList[id]?.status === 'active')
    }

    shouldAddEntry(entry: any, calEntry: CalendarEntry, entries: CalendarEntry[], active: boolean): boolean {
        const parts = entry.start.split('-')
        const startDate = moment(new Date(parts[0], parts[1] - 1, parts[2]))
        const currentDate = moment()

        const hasDependents = entry.dependent_ids.length > 0;
        const isCenterClosure = calEntry.entryType === 'center_closure';
        const isAbsence = entry.entry_type === 'absence';
        const isCalEntryInEntries = entries.some(e => e.id === calEntry.id);

        if ((hasDependents && !isCenterClosure) || (isCenterClosure && !isCalEntryInEntries)) {
            if (isAbsence) {
                return active || currentDate > startDate
            }    
            else {
                return true
            }    
        }
        return false
    }

    createEntry(entry: any, calEntry: CalendarEntry, firstNameList: string[]): CalendarEntry {
        return {
            ...calEntry,
            dependentId: entry.dependent_ids,
            dependentNames: firstNameList.join('; ')
        }
    }
}
